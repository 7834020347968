"use client";
import { useGlobalStore } from "@/lib/globalStore";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { FaArrowRight } from "react-icons/fa6";
const Footer = () => {
  const { setGetStartedOpen } = useGlobalStore();
  const QuickLinks = () => {
    return (
      <>
        <div className="col-span-1 flex flex-col gap-3 font-bold min-w-[120px] lg:min-w-[150px] lg:pl-4">
          <div className="hidden md:flex ">
            <small className="text-[10px] uppercase font-bold text-main-accent">
              Why Enviroplus
            </small>
          </div>
          <div>
            <Link href="/meet-your-esg-goals">Easy ESG</Link>
          </div>
          <div>
            <Link href="/environmental-impact">Environmentally Safer</Link>
          </div>
          <div>
            <Link href="/effective-cleaning">Better Cleaning</Link>
          </div>
          <div>
            <Link href="/healthy-workplaces">Healthier Workplace</Link>
          </div>
        </div>
        <div className="col-span-1 flex flex-col gap-3 font-bold min-w-[120px] lg:min-w-[150px] pl-4">
          <div>
            <Link href="/products">Products</Link>
          </div>
          <div>
            <Link href="/mission">Mission</Link>
          </div>
          <div>
            <Link href="/learn">Learn</Link>
          </div>
          <div>
            <Link href="/contact-us">Contact Us</Link>
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-3 font-bold min-w-[120px] lg:min-w-[150px] lg:pl-4">
          <div>
            <Link href="/about-enviroplus">About Enviroplus</Link>
          </div>
          <div>
            <Link href="/meet-eden">Meet Eden</Link>
          </div>
          <div>
            <Link href="/distributor-locations">Distributor Locations</Link>
          </div>
        </div>
        <div className="col-span-2 ml-0 md:ml-auto   flex flex-col gap-2 min-w-[120px]">
          <div>
            <a href="tel:1800177399">Tel: 1800 177 399</a>
          </div>
          <div>
            <a
              className="whitespace-normal break-all"
              href="mailto:sales@enviroplusproducts.com.au"
            >
              sales@enviroplusproducts.com.au
            </a>
          </div>
        </div>
      </>
    );
  };
  const EngagementHub = () => {
    return (
      <div className="flex flex-col md:flex-row gap-6">
        <div className="flex-1">
          <div className="w-full h-[2px] bg-main-accent mb-6"></div>
          <div className="mb-6">
            <p className="text-lg font-semibold mb-4 md:gap-6">
              Trial our products
            </p>
            <p className="leading-[22px]">
              Make an educated choice by trialing our products before you make a
              commitment.{" "}
            </p>
          </div>
          <div
            onClick={() => setGetStartedOpen(true)}
            className="flex group cursor-pointer justify-between mb-4 md:gap-6 "
          >
            <p className="font-semibold border-b border-transparent group-hover:border-white transition-all">
              Request free trial
            </p>
            <div className="flex justify-center items-center transition-all rounded-[2.6px] group-hover:translate-x-2 w-6 h-6 bg-main-accent md:mr-auto ">
              <FaArrowRight className="text-main-dark-green " />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="w-full h-[2px] bg-main-accent mb-6"></div>
          <div className="mb-6">
            <p className="text-lg font-semibold mb-4">Get in contact</p>
            <p className="leading-[22px]">
              Reach environmental targets with greener cleaning. Find out how.
            </p>
          </div>
          <div
            onClick={() => setGetStartedOpen(true)}
            className="flex cursor-pointer group justify-between mb-4 md:gap-6"
          >
            <p className="font-semibold border-b border-transparent group-hover:border-white transition-all">
              Chat to us
            </p>
            <div className="transition-all flex justify-center items-center rounded-[2.6px] group-hover:translate-x-2 w-6 h-6 bg-main-accent md:mr-auto">
              <FaArrowRight className="text-main-dark-green  " />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="w-full h-[2px] bg-main-accent mb-6"></div>
          <div className="mb-6">
            <p className="text-lg font-semibold mb-4">Find a distributor</p>
            <p className="leading-[22px]">
              Order products from a distributor near you. Get in touch with our
              friendly team.
            </p>
          </div>
          <div
            onClick={() => setGetStartedOpen(true)}
            className="flex group justify-between mb-4 cursor-pointer md:gap-6"
          >
            <p className="font-semibold border-b border-transparent group-hover:border-white transition-all">
              Get in touch
            </p>
            <div className="flex justify-center items-center rounded-[2.6px] transition-all group-hover:translate-x-2 w-6 h-6 bg-main-accent md:mr-auto">
              <FaArrowRight className="text-main-dark-green " />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <footer className="bg-main-dark-green">
      <div className={cn(`pt-20 container text-white `)}>
        <div className="sm:flex-col md:flex lg:flex-row md:gap-4">
          <div className="md:flex gap-4 logo">
            <div>
              <img
                src="/enviroplus-logo-dark-footer.svg"
                alt="Enviroplus Logo"
                className="w-40 mb-8  md:w-full"
              />
            </div>
          </div>
          <div className="grid gap-1 engagement md:hidden">
            <EngagementHub />
            <div className="w-full h-[2px] bg-main-accent mb-6"></div>
          </div>
          <div className="pt-4 mb-4 md:hidden ">
            <small className="text-[10px] uppercase font-bold text-main-accent">
              Why Enviroplus
            </small>
          </div>
          <div className="pb-8 md:col-span-3 grid grid-cols-2 lg:ml-auto md:gap-4 lg:gap-12 md:flex gap-y-8 gap-x-4 quick-link-list">
            <QuickLinks />
          </div>
        </div>
        <div className="hidden md:flex md:gap-8  md:py-10">
          <EngagementHub />
        </div>
        <div className="flex gap-4 flex-col md:flex-row-reverse md:items-center md:justify-between md:mt-8 md:mb-10">
          <div className="hidden sm:flex gap-4 mb-8 md:mb-0">
            {/* <img src="/geca.png" alt="GECA Certification" />
          <img src="/phd.png" alt="GECA Certification" /> */}
          </div>
          <div className="flex items-center gap-4">
            <div>
              <img
                src="/auflag.png"
                className="w-[50px] min-w-[50px]"
                alt="Australian Flag"
              />
            </div>
            <p className="font-bold">
              Enviroplus is an Australian-owned company distributing nationally.
            </p>
          </div>
        </div>
        <div className="mt-20 md:justify-between md:items-center md:flex text-[#F5F5F6] pb-6">
          <p className="text-xs mb-4 md:mb-0">
            © {new Date().getFullYear()} Enviroplus. All rights reserved.
          </p>
          <ul className="text-xs flex gap-8">
            <li className="py-2">
              <Link href={"/privacy-policy"}>Privacy Policy</Link>
            </li>
            <li className="py-2">
              <Link href={"/terms-and-conditions"}>Terms and Conditions</Link>
            </li>
            <li className="py-2">
              <Link href={"/website-disclaimer"}>Website Disclaimer</Link>
            </li>
          </ul>
          <div className="w-32 h-2"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
