import { cn } from "@/lib/utils";
import React from "react";
import { HTMLMotionProps, motion } from "framer-motion";
interface NavCardProps extends HTMLMotionProps<"div"> {
  image?: string;
  children?: React.ReactNode;
}
const footerAnimation = {
  rest: {
    opacity: 0,
    ease: "spring",
    duration: 0.2,
    type: "tween",
    translateY: 25,
  },
  hover: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeIn",
    },
  },
};
const NavCard = React.forwardRef<HTMLDivElement, NavCardProps>(
  ({ className, children, image, ...props }, ref) => {
    return (
      <motion.div
        initial="rest"
        whileHover="hover"
        animate="rest"
        ref={ref}
        className={cn(
          `relative group/navCard rounded-tl-2xl rounded-tr-2xl rounded-br-2xl p-8 h-full shadow-sm overflow-hidden`,
          className
        )}
        {...props}
      >
        <div className="w-full h-full rounded-tl-2xl rounded-tr-2xl rounded-br-2xl bg-main-dark-green-light absolute top-0 left-0"></div>
        {image && (
          <div
            style={{ backgroundImage: `url(${image})` }}
            className="w-full h-full bg-center bg-cover rounded-tl-2xl rounded-tr-2xl rounded-br-2xl opacity-0 transition-all group-hover/navCard:opacity-100 absolute top-0 left-0"
          ></div>
        )}
        {!image && (
          <div className="w-full h-full bg-[#1E694D] bg-center bg-cover rounded-tl-2xl rounded-tr-2xl rounded-br-2xl opacity-0 transition-all group-hover/navCard:opacity-100 absolute top-0 left-0"></div>
        )}
        <div className="relative z-[4] flex flex-col h-full">{children}</div>
      </motion.div>
    );
  }
);
NavCard.displayName = "NavCard";

const NavCardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-[27px] font-semibold leading-[130%] tracking-tight text-white",
      className
    )}
    {...props}
  />
));
NavCardTitle.displayName = "NavCardTitle";

const NavCardFooter = React.forwardRef<
  HTMLParagraphElement,
  HTMLMotionProps<"div"> & { whileHover?: any }
>(({ className, whileHover, ...props }, ref) => (
  <motion.div
    variants={footerAnimation}
    ref={ref}
    className={cn("mt-auto", className)}
    {...props}
  />
));
NavCardFooter.displayName = "NavCardFooter";

export { NavCard, NavCardTitle, NavCardFooter };
