const subMenuAnimate = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
    display: "block",
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      delay: 0.2,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

export { subMenuAnimate };
