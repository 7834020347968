"use client";
import * as React from "react";
import { subMenuAnimate } from "@/lib/animationVariants";
import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { IoChevronDownSharp } from "react-icons/io5";
type Props = {
  label: string;
  children: any;
  forceOpen?: boolean;
  isLight?: boolean;
};

const MegaMenuDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLight, label, children, forceOpen } = props;
  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        isOpen: isOpen,
        setIsOpen: setIsOpen,
      });
    });
  };
  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      className="flex h-full justify-center items-center mx-4 z-[999]"
    >
      <p
        className={cn(
          "mr-2 font-semibold cursor-pointer whitespace-nowrap",
          isLight ? "text-main-dark-green" : "text-white"
        )}
      >
        {label}
      </p>{" "}
      <IoChevronDownSharp
        className={cn(
          "text-xl  group-hover:rotate-180 transition-all",
          isLight ? "text-main-dark-green" : "text-white"
        )}
      />
      <AnimatePresence>
        {(isOpen || forceOpen) && (
          <motion.div
            // initial="hidden"
            // animate="visible"
            // exit={"hidden"}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            variants={subMenuAnimate}
            className={cn(
              " flex-col mt-10 top-[72px] z-99 absolute left-0  w-full bg-main-dark-green text-white",
              isLight && "bg-white text-main-dark-green"
            )}
          >
            {renderChildren()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MegaMenuDropdown;
