import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/atoms/UtmHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/layouts/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/layouts/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/organisms/FloatingChat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-sanity/dist/visual-editing/client-component.js");
