"use client";

import { useEffect, useMemo, useState } from "react";
import Eden from "../molecules/Eden/Eden";
import { AnimatePresence, motion } from "framer-motion";
import { floatingData } from "@/app/data";
import { FaArrowLeft } from "react-icons/fa6";
import { TfiReload } from "react-icons/tfi";
import { RxCaretRight } from "react-icons/rx";
import { v4 as uuidv4 } from "uuid";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { FaArrowRight } from "react-icons/fa";
import useClickOutside from "@/hooks/useClickOutside";

type Props = {};
const linkParentMenus = (menus: any, title = null) => {
  if (menus?.length) {
    for (let menu of menus) {
      menu.parentMenu = title;
      linkParentMenus(menu.submenu, menu.menuTitle);
    }
  }
};

const createRootMenu = (menus: any) => {
  const menu = {
    title: "main",
    submenu: menus,
    parentMenu: null,
  };
  linkParentMenus([menu]);
  return menu;
};
const findSubmenu = (menu: any, title: any) => {
  if (menu?.menuTitle === title) {
    return menu;
  }
  if (menu?.submenu?.length) {
    for (let submenu of menu.submenu) {
      let found: any = findSubmenu(submenu, title);
      if (found != null) {
        return found;
      }
    }
  }
  return null;
};

const rootMenu = createRootMenu(floatingData);
const FloatingChat = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Now that we are client-side, it's safe to check the window object
    const updateIsDesktop = () => setIsDesktop(window.innerWidth >= 768);
    updateIsDesktop(); // Call once to set initial value based on client-side window

    // Set up event listener for window resize
    window.addEventListener("resize", updateIsDesktop);

    // Clean up the event listener when the component unmounts or re-renders
    return () => window.removeEventListener("resize", updateIsDesktop);
  }, []);

  useEffect(() => {
    const toggleVisibility = () => {
      if (
        window.pageYOffset > 500 &&
        window.pageYOffset <
          document.documentElement.scrollHeight - window.innerHeight - 800
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const chatVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
      x: isDesktop ? -48 : 0,
      transformOrigin: "bottom right",
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.4,
      },
    },
  };

  const [activeMenuTitle, setActiveMenuTitle] = useState("main");
  const [heading, setHeading] = useState("What would you like to do?");

  const activeMenu = useMemo(() => {
    return findSubmenu(rootMenu, activeMenuTitle) ?? rootMenu;
  }, [activeMenuTitle]);
  const handleClickOutside = () => {
    setIsOpen(false);
    setHeading("What would you like to do?");
    setActiveMenuTitle("main");
  };

  const ref = useClickOutside(handleClickOutside);
  return (
    <div
      ref={ref}
      className={cn(
        `fixed z-[9999] transition-all opacity-0 right-3 bottom-3`,
        isVisible ? "opacity-100" : ""
      )}
    >
      <div className="relative">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={chatVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="rounded-3xl sm:translate-x-[-48px] max-w-[500px] min-h-[620px] flex flex-col rounded-br-none py-8 px-6 bg-white min-w-[380px] sm:min-w-[500px] "
            >
              <div className="relative flex items-center flex-col">
                <TfiReload
                  size={24}
                  className="absolute cursor-pointer transition-all  right-0 top-0"
                  onClick={() => {
                    setActiveMenuTitle("main");
                    setHeading("What would you like to do?");
                  }}
                />
                <div className="w-12 h-12 mb-3">
                  <Eden className="rounded-2xl !gap-[0.2rem]" />
                </div>
                <p className="text-main-dark-green/50 mb-2 font-semibold">
                  Hi I'm Eden!
                </p>
                <p className="text-main-dark-green text-[22px]">
                  {/* What would you like to do? */}
                  {heading}
                </p>
              </div>
              <div className="py-6 flex-1 flex flex-col gap-2">
                <AnimatePresence mode="popLayout">
                  {activeMenu && !activeMenu.submenu ? (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="flex flex-col rounded-[8px] p-4 bg-[#ecfeee] "
                      key={uuidv4()}
                    >
                      <p className="mb-8">{activeMenu.content}</p>
                      <Link
                        href={activeMenu.button.link}
                        onClick={() => {
                          setActiveMenuTitle("main");
                          setHeading("What would you like to do?");
                          handleOpen();
                        }}
                        className="text-black gap-4 flex items-center"
                      >
                        <p className="ml-auto text-sm font-semibold ">
                          {activeMenu.button.title}
                        </p>
                        <div className="w-9 h-9 rounded-[4px] flex items-center justify-center bg-main-accent">
                          <FaArrowRight size={24} className="" />
                        </div>
                      </Link>
                    </motion.div>
                  ) : (
                    activeMenu.submenu.map((item: any, i: any) => {
                      return (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          // layout
                          onClick={() => {
                            setHeading(item.heading);
                            setActiveMenuTitle(item.menuTitle);
                          }}
                          // exit={{ opacity: 0 }}
                          className="p-3 border-2 border-transparent hover:border-main-accent cursor-pointer bg-[#ecfeee] hover:bg-[#31EB97]/30 transition-all rounded-[8px] flex justify-between items-center"
                        >
                          <p>{item.menuTitle}</p>
                          <RxCaretRight size={24} />
                        </motion.div>
                      );
                    })
                  )}
                </AnimatePresence>
                <div
                  className={cn(
                    "flex items-center gap-4 cursor-pointer border-t border-black/10 pt-4 mt-auto",
                    activeMenuTitle === "main" && "hidden"
                  )}
                  onClick={() => {
                    setActiveMenuTitle(activeMenu.parentMenu ?? "main");
                  }}
                >
                  <FaArrowLeft /> <p>Back</p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div
          onClick={() => {
            handleOpen();
          }}
          className="cursor-pointer float-right inline-flex rounded-[8px] items-center bg-white"
        >
          <AnimatePresence>
            {!isOpen && (
              <motion.div
                initial={{
                  height: "64px",
                }}
                animate={{
                  height: isOpen ? "48px" : "64px",
                }}
                className="px-4  py-[18px]"
              >
                <p className="font-semibold text-[18px]">How can I help?</p>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            initial={{
              width: "64px",
              height: "64px",
            }}
            animate={{
              width: isOpen ? "48px" : "64px",
              height: isOpen ? "48px" : "64px",
            }}
          >
            <Eden
              icon={isOpen ? "close" : ""}
              className={cn(
                " !rounded-r-[8px] !gap-[0.2rem]",
                isOpen
                  ? "!rounded-tl-none !rounded-bl-[8px]"
                  : "!rounded-l-none"
              )}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FloatingChat;
